import React from "react"
import Seo from "../../components/seo"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import DescriptionComponent from "../../components/DescriptionComponent"
import ToolsComponent from "../../components/ToolsComponent"

const projectTemplate = ({ data }) => {
  const projectData = data.strapiProject
  const projectSEO = data.strapiProject.SEO
  const coverImage = getImage(projectData.cover.localFile)
  return (
    <>
      <Seo
        title={projectSEO.metaTitle}
        description={projectSEO.metaDescription}
        image={projectSEO.sharedImage.media.url}
        imageAlt={projectSEO.sharedImage.alt}
        preventIndexing={projectSEO.preventIndexing}
      />
      <div className="page-content post-template single-project">
        <header className="upper-content">
          <div className="header-content">
            <h1>{projectData.title}</h1>
            <div className="details">
              <h5>{projectData.tag}</h5>
              <h5>{projectData.completed}</h5>
            </div>
          </div>
        </header>
        <main className="lower-content">
          <GatsbyImage
            className="cover-image"
            image={coverImage}
            alt={projectData.cover.alternativeText}
          />
          <div className="divided-content">
            {(projectData.design_tools.length > 0 ||
              projectData.build_tools.length > 0 ||
              projectData.link) && (
              <aside className="quickfacts">
                {projectData.design_tools.length > 0 && (
                  <div className="designed">
                    <h4>Designed with</h4>
                    <div className="logo-wrapper">
                      {projectData.design_tools.map((tool, index) => {
                        return <ToolsComponent data={tool} key={index} />
                      })}
                    </div>
                  </div>
                )}
                {projectData.build_tools.length > 0 && (
                  <div className="built">
                    <h4>Built with</h4>
                    <div className="logo-wrapper">
                      {projectData.build_tools.map((tool, index) => {
                        return <ToolsComponent data={tool} key={index} />
                      })}
                    </div>
                  </div>
                )}
                {projectData.link && (
                  <a href={projectData.link}>
                    <button>Visit Project</button>
                  </a>
                )}
              </aside>
            )}
            <div className="main-description">
              {projectData.description && (
                <div className="only-rich-text">
                  <ReactMarkdown
                    children={projectData.description}
                    remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                  />
                </div>
              )}
              {projectData.description_component.length > 0 && (
                <DescriptionComponent
                  data={projectData.description_component}
                />
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default projectTemplate

export const query = graphql`
  query ($id: String) {
    strapiProject(id: { eq: $id }) {
      title
      slug
      exerpt
      description
      completed(formatString: "MMMM Do, YYYY")
      link
      tag
      SEO {
        metaTitle
        metaDescription
        preventIndexing
        sharedImage {
          alt
          media {
            url
          }
        }
      }
      design_tools {
        iconify_icon
        title
      }
      build_tools {
        iconify_icon
        title
      }
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
        alternativeText
      }
      description_component {
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
          alternativeText
          caption
        }
        image_cards
        image_floated
      }
    }
  }
`
