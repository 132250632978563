import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"

const descriptionComponent = ({ data }) => {
  return data.map((component, index) => {
    return (
      <div className="component-content" key={index}>
        {component.image !== "undefined" && component.image_cards && (
          <div className="image-cards">
            {component.image.map((image, index) => {
              return (
                <div className="image-card" key={index}>
                  <GatsbyImage
                    image={getImage(image.localFile)}
                    alt={image.alternativeText}
                    className="description-image"
                  />
                  <p className="caption">{image.caption}</p>
                </div>
              )
            })}
          </div>
        )}
        {component.image !== "undefined" &&
          component.image_floated &&
          component.image.map((image, index) => {
            return (
              <GatsbyImage
                image={getImage(image.localFile)}
                alt={image.alternativeText}
                className="description-image image-floated"
                key={index}
              />
            )
          })}
        {component.image !== "undefined" &&
          !component.image_floated &&
          !component.image_cards &&
          component.image.map((image, index) => {
            return (
              <div className="full-image-container">
                <GatsbyImage
                  image={getImage(image.localFile)}
                  alt={image.alternativeText}
                  className="description-image"
                  key={index}
                />
                {image.caption && <p className="caption">{image.caption}</p>}
              </div>
            )
          })}
        {component.description && (
          <ReactMarkdown
            children={component.description}
            remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
          />
        )}
      </div>
    )
  })
}

export default descriptionComponent
