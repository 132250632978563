import { Icon } from "@iconify/react"
import React from "react"

const ToolsComponent = ({ data }) => {
  return (
    <div className="tools-component">
      <Icon icon={data.iconify_icon} width="55px" />
      <div className="popup">
        <p>{data.title}</p>
      </div>
    </div>
  )
}

export default ToolsComponent
